import * as Sentry from '@sentry/react'
import { authenticatedFetch } from '@shopify/app-bridge/utilities'
import { Exchange } from '@urql/core'
import { Client, createClient, fetchExchange } from 'urql'
import { v4 as uuidv4 } from 'uuid'
import { pipe, tap } from 'wonka'

import { getToken } from 'auth'
import { GRAPHQL_ENDPOINT } from 'config'
import { getShopifyApp } from 'utils/shopify'

import cache from './cache'

let traceId: string | null = null
const traceIds: { [key: string]: string } = {}
let graphqlClient: null | Client = null

export const refreshTraceId = (path: string): void => {
  if (!traceIds[path]) {
    traceIds[path] = uuidv4()
  }
  traceId = traceIds[path]
}

const sentryErrorExchange: Exchange =
  ({ forward }) =>
  (ops$) => {
    return pipe(
      forward(ops$),
      tap(({ error }) => {
        if (error) {
          Sentry.captureException(error)
        }
      }),
    )
  }

export const initGraphQLClientEmbedded = (): Client => {
  graphqlClient = createClient({
    url: GRAPHQL_ENDPOINT,
    // @ts-expect-error: This works, promise
    fetch: authenticatedFetch(getShopifyApp()),
    exchanges: [cache, sentryErrorExchange, fetchExchange],
  })
  return graphqlClient
}

export const initGraphQLClientStandalone = (): Client => {
  const token = getToken()
  graphqlClient = createClient({
    url: GRAPHQL_ENDPOINT,
    fetchOptions: () => {
      const headers: RequestInit['headers'] = {}
      if (!traceId) refreshTraceId(window.location.pathname)
      if (traceId) headers['x-trace-id'] = traceId
      if (token) headers.Authorization = `Bearer ${token}`
      return { headers }
    },

    exchanges: [cache, sentryErrorExchange, fetchExchange],
  })
  return graphqlClient
}

export const getGraphQLClient = () => graphqlClient!
