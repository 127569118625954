import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/store'

import { UpdateSettingsMutationVariables } from 'gql'

const selectOnboarding = (s: RootState) => s.onboarding

export const selectOnboardingOpen = createSelector([selectOnboarding], (ob) => ob.open)
export const selectOnboardingPunted = createSelector([selectOnboarding], (ob) => ob.punted)

export const selectInitialOnboardingData = createSelector([selectOnboarding], (ob) => ob.initial)
export const selectCurrentOnboardingData = createSelector([selectOnboarding], (ob) => ob.current)

export const selectOnboardingMutationVars = createSelector(
  [selectInitialOnboardingData, selectCurrentOnboardingData],
  (initial, current): UpdateSettingsMutationVariables | void => {
    if (initial && current) {
      const vars: UpdateSettingsMutationVariables = {
        enableRefund: current.policies.refund,
        enableExchange: current.policies.exchange,
        enableStoreCredit: current.policies.credit,
        shipFree: current.shipping.prepaid,
        shipPay: current.shipping.payForLabel,
        shipSelf: current.shipping.self,
        defaultAddress: current.address,
        onboardingComplete: true,
      }
      if (initial.approval.type !== current.approval.type) {
        vars.useApprovals = current.approval.type === 'review'
        vars.requireApprovalForAllReturns = current.approval.type === 'review'
      }
      return vars
    }
  },
)
