import { Loading } from '@shopify/polaris'
import { lazy, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { selectShopReturnTypes } from 'store/global/global.selectors'
import { _, useAppDispatch } from 'store/hooks'

import { useCommonData } from 'common/useCommonData'
import { DispositionType, ReturnType, useRmaQuery } from 'gql'
import { notEmpty } from 'utils'

import { clearRMASlice, handleData, resetRMAState } from './slice/rma.slice'

const RMAPage = lazy(() => import('./RMAPage'))

export const RMALoader = () => {
  const params = useParams()
  const dispatch = useAppDispatch()

  const availableReturnTypes = _(selectShopReturnTypes)

  const returnId = parseInt(params.id!)
  useEffect(() => {
    dispatch(resetRMAState())
  }, [returnId])
  const [rmaState] = useCommonData(useRmaQuery, {
    variables: { returnId },
  })
  useEffect(() => {
    const { data, error } = rmaState

    if (error) console.error(error)
    if (!data?.getReturn?.items) return

    const consolidated = !!data.getReturn.items.find((i) => i?.exchangeOrder?.consolidatedOrderExchange)
    const nonExchangeItems = data.getReturn.items.filter(notEmpty).filter((i) => i.returnType !== ReturnType.Exchange)
    const nonExchangeTypes = [...new Set(nonExchangeItems.map((item) => item.returnType))]
    const singleNonExchangeType = nonExchangeTypes.length === 1 ? nonExchangeTypes[0] : null
    let consolidatedExchangeMethod =
      singleNonExchangeType ||
      (data.getMyShop?.resolvePartialExchangeRefundsToStoreCredit ? ReturnType.Credit : ReturnType.Refund)
    if (consolidatedExchangeMethod === ReturnType.Refund && !availableReturnTypes.includes(ReturnType.Refund))
      consolidatedExchangeMethod = ReturnType.Credit
    if (consolidatedExchangeMethod === ReturnType.Credit && !availableReturnTypes.includes(ReturnType.Credit))
      consolidatedExchangeMethod = ReturnType.Refund

    dispatch(
      handleData({
        rma: {
          ...data.getReturn,
          items: data.getReturn.items.filter(notEmpty).map((item) => {
            const storeCredit = data.getReturn!.storeCredits?.find((sc) =>
              sc?.returnItems.find((i) => i.id === item.id),
            )
            return {
              ...item,
              returnType:
                consolidated && item.returnType === ReturnType.Exchange ? consolidatedExchangeMethod : item.returnType,
              isRestocked: item.dispositionType === DispositionType.Restocked,
              isExpired: !!item.expiredAt,
              dispositionType: undefined,
              storeCredit,
            }
          }),
        },
      }),
    )
  }, [rmaState.data, rmaState.error])

  useEffect(
    () => () => {
      dispatch(clearRMASlice)
    },
    [],
  )

  return (
    <>
      {(rmaState.fetching || rmaState.stale) && <Loading />}
      <RMAPage />
    </>
  )
}

export default RMALoader
