import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'

import { useCommonData } from 'common/useCommonData'
import { useReturnListPageQuery } from 'gql'
import { useStickyState } from 'utils/sticky'

import { FILTERS_STORAGE_KEY, INITIAL_FILTERS, IReturnFilters } from './Filters'

// import ReturnsPage from './ReturnsPage'

const ReturnsPage = lazy(() => import('./ReturnsPage'))

export const ReturnsPageLoader: FC = () => {
  const [filters, setStickyFilters] = useStickyState(FILTERS_STORAGE_KEY, INITIAL_FILTERS)
  const setFilters = (newFilters: IReturnFilters) => {
    if (newFilters.currentPage === filters.currentPage) {
      // If not changing page then set page to 0 to make sure user doesn't end up
      // on page that doesn't exist'
      setStickyFilters({ ...newFilters, currentPage: 0 })
    } else {
      setStickyFilters({ ...newFilters })
    }
  }
  filters.returnDate.start = new Date(filters.returnDate.start)
  filters.returnDate.end = new Date(filters.returnDate.end)
  filters.orderDate.start = new Date(filters.orderDate.start)
  filters.orderDate.end = new Date(filters.orderDate.end)

  // Fallback since localStorage doesn't have stageUpdatedDate key
  filters.stageUpdatedDate.start = new Date(filters.stageUpdatedDate.start)
  filters.stageUpdatedDate.end = new Date(filters.stageUpdatedDate.end)

  const [{ data, fetching, stale }, refreshQuery] = useCommonData(
    useReturnListPageQuery,
    { variables: filters },
    'returns',
  )

  return (
    <>
      {(fetching || stale) && <Loading />}
      <ReturnsPage
        returnList={data?.returnList}
        fetching={false}
        filters={filters}
        updateFilters={setFilters}
        refresh={() => refreshQuery({ requestPolicy: 'network-only' })}
      />
    </>
  )
}
