import { useBreakpoints } from '@shopify/polaris'
import { FC } from 'react'
import { selectShop } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

import { CompleteOnboardingBanner } from './CompleteOnboardingBanner'
import { MissingPermissionsBanner } from './MissingPermissionsBanner'
import { NoPackageSizeBanner } from './NoPackageSizeBanner'
import { NoShippingAccountBanner } from './NoShippingAccountBanner'
import { SelectAPlanBanner } from './SelectAPlanBanner'
import { TestReturnsBanner } from './TestReturnsBanner'

type AvailableBanners =
  | 'missing-permissions'
  | 'onboarding-pending'
  | 'no-package-size'
  | 'no-shipping-account'
  | 'test-returns'
  | 'select-a-plan'

type BannerProps = {
  horizontalPadding?: boolean
  noTopMargin?: boolean
  banners: AvailableBanners[]
  refreshReturns?: () => unknown
}

export const Banners: FC<BannerProps> = ({ banners, refreshReturns, horizontalPadding, noTopMargin }) => {
  const { smDown } = useBreakpoints()
  const shop = _(selectShop)
  const onboardingPending = !shop.onboardingComplete
  return (
    <Container horizontalPadding={horizontalPadding && !smDown} noTopMargin={noTopMargin}>
      {banners.includes('onboarding-pending') && onboardingPending ? (
        <>
          <CompleteOnboardingBanner />
          {banners.includes('missing-permissions') && <MissingPermissionsBanner />}
          {banners.includes('select-a-plan') && <SelectAPlanBanner />}
        </>
      ) : (
        <>
          {banners.includes('select-a-plan') && <SelectAPlanBanner />}
          {banners.includes('missing-permissions') && <MissingPermissionsBanner />}
          {banners.includes('no-package-size') && <NoPackageSizeBanner />}
          {banners.includes('no-shipping-account') && <NoShippingAccountBanner />}
          {banners.includes('test-returns') && <TestReturnsBanner refreshReturns={refreshReturns} />}
        </>
      )}
    </Container>
  )
}

const Container = styled.section<{ horizontalPadding?: boolean; noTopMargin?: boolean }>`
  ${(p) =>
    p.horizontalPadding &&
    css`
      padding: 0 var(--p-space-600);
    `}
  > .Polaris-Banner {
    ${(p) =>
      !p.noTopMargin &&
      css`
        &:first-child {
          margin-top: var(--p-space-600);
        }
      `}
    &:last-child {
      margin-bottom: var(--p-space-600);
    }
  }
`
