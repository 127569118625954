import { Card, Icon, Tooltip } from '@shopify/polaris'
import { InfoIcon } from '@shopify/polaris-icons'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { selectAllShopStages } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

import { useElementWidth } from 'common/useElementWidth'
import { useViewportWidth } from 'common/useViewportWidth'
import { DashboardQuery, Maybe, SystemStage } from 'gql'
import { DEFAULT_EASING } from 'onboarding/onboarding-styles'
import { notEmpty } from 'utils'

import { ActiveReturnsLegend, LEGEND_BULLET_DIAMETER, LEGEND_HORIZONTAL_PADDING } from './ActiveReturnsLegend'
import { ChartName } from './ChartName'
import { DataRow } from './DataRow'
import { DataRows } from './DataRows'
import { ReferenceColor } from './ReferenceColor'
import { ReferencesPlaceholder } from './ReferencesPlaceholder'
import { CHART_PLACEHOLDER_COLOR, getStagePaletteIndex, STAGE_PALETTE } from './dashboard-values'

type ActiveReturnsSlice = Exclude<
  Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['activeReturns'],
  undefined | null
>
type ActiveReturnsProps = {
  activeReturnsData: ActiveReturnsSlice
}

type ActiveReturnsSliceStage = Exclude<Exclude<ActiveReturnsSlice['stage'], undefined | null>, undefined | null>

type EnrichedStage = ActiveReturnsSliceStage[number] & {
  start: number
  end: number
  name?: string
  systemStage?: Maybe<SystemStage>
  customIndex: number
  percent: number
}

const COLLAPSED_LEGEND_OFFSET = LEGEND_BULLET_DIAMETER + LEGEND_HORIZONTAL_PADDING

export const ActiveReturnsChart: FC<ActiveReturnsProps> = ({ activeReturnsData: { count, stage: stageData } }) => {
  const navigate = useNavigate()
  const stages = _(selectAllShopStages)

  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)
  const [dotOnly, setDotOnly] = useState<number[]>([])
  const [hoverLefts, setHoverLefts] = useState(new Map<number, number>())

  const [enrichedStages, setEnrichedStages] = useState<EnrichedStage[]>([])

  useEffect(() => {
    if (stages) {
      let ratioDone = 0
      const withSystemStage = stageData!.map((s) => ({
        ...s,
        systemStage: stages.find((stage) => stage.id === s!.stageId)?.systemStage,
      }))
      const sorted = [
        SystemStage.PendingApproval,
        SystemStage.AutomaticallyApproved,
        SystemStage.Approved,
        SystemStage.Received,
      ]
        .map((ss) => withSystemStage.find((s) => s.systemStage === ss))
        .filter(notEmpty)
        .concat(withSystemStage.filter((s) => !s.systemStage))
        .concat([withSystemStage.find((s) => s.systemStage === SystemStage.Rejected)].filter(notEmpty))
      const enriched = sorted.map((s) => ({
        ...s,
        customIndex: 0,
        start: ratioDone,
        end: (ratioDone += s.count! / count!),
        percent: Math.round((s.count! / count!) * 100),
      }))
      enriched.filter((s) => !s.systemStage).forEach((s, i) => (s.customIndex = i))
      setEnrichedStages(enriched)
    }
  }, [stageData, stages])

  const [legendsRef, legendsWidth] = useElementWidth<HTMLDivElement>()
  useEffect(() => {
    const dotOnly = []
    const hoverLefts = new Map<number, number>()
    if (legendsWidth) {
      if (legendsRef.current?.children.length) {
        let totalLengthTaken = 0
        for (let i = 0; i < legendsRef.current.children.length; i++) {
          const legend = legendsRef.current.children[i] as HTMLAnchorElement

          legend.classList.add('ignore-dot-only')

          const start = enrichedStages[i].start
          const end = enrichedStages[i].end

          const minStart = start * legendsWidth
          const actualStart = Math.max(minStart, totalLengthTaken)
          legend.style.left = actualStart + 'px'

          const bandWidth = legendsWidth * (end - start)
          const maxWidth = bandWidth - LEGEND_HORIZONTAL_PADDING

          let lengthTaken = legend.offsetWidth
          if (legend.offsetWidth > maxWidth - (actualStart - minStart)) {
            lengthTaken = COLLAPSED_LEGEND_OFFSET
            dotOnly.push(i)
          }

          totalLengthTaken = actualStart + lengthTaken
        }

        let maxRight = legendsWidth + LEGEND_HORIZONTAL_PADDING
        for (let i = legendsRef.current.children.length - 1; i >= 0; i--) {
          const legend = legendsRef.current.children[i] as HTMLAnchorElement

          const left = parseFloat(legend.style.left)
          const restingWidth = dotOnly.includes(i) ? COLLAPSED_LEGEND_OFFSET : legend.offsetWidth
          const right = left + restingWidth

          const offset = right > maxRight ? right - maxRight : 0
          const newLeft = left - offset
          legend.style.left = newLeft + 'px'

          const openedRight = newLeft + legend.offsetWidth
          if (openedRight > legendsWidth) hoverLefts.set(i, legendsWidth - legend.offsetWidth)

          maxRight = newLeft
          legend.classList.remove('ignore-dot-only')
        }
      }
    }
    setHoverLefts(hoverLefts)
    setDotOnly(dotOnly)
  }, [enrichedStages, legendsRef.current, legendsWidth])

  const viewportWidth = useViewportWidth()

  return (
    <Container>
      <Card>
        <Texts
          href="/returns"
          onClick={(e) => {
            e.preventDefault()
            navigate('/returns')
          }}
        >
          <Count>
            <FormattedNumber value={count!} />
          </Count>
          <ChartNameAndChange>
            <ChartName>
              {count === 1 ? (
                <FormattedMessage id="dashboard.activeReturn" defaultMessage="Active return" />
              ) : (
                <FormattedMessage id="dashboard.activeReturns" defaultMessage="Active returns" />
              )}
              <Tooltip
                width="wide"
                padding="400"
                preferredPosition="above"
                content={
                  <FormattedMessage
                    id="dashboard.activeReturnsTooltip"
                    defaultMessage="This shows the stage of active returns created at any point."
                  />
                }
              >
                <IconContainer>
                  <Icon source={InfoIcon} tone="subdued" />
                </IconContainer>
              </Tooltip>
            </ChartName>
          </ChartNameAndChange>
        </Texts>
        <Chart>
          {enrichedStages.length ? (
            enrichedStages.map((s, i) => (
              <Segment
                key={i}
                start={s.start}
                end={s.end}
                highlighted={i === highlightedIndex}
                onMouseEnter={() => setHighlightedIndex(i)}
                onMouseLeave={() => setHighlightedIndex((current) => (current === i ? null : current))}
              >
                <ReferenceColor index={getStagePaletteIndex(s.customIndex, s.systemStage)} palette={STAGE_PALETTE} />
              </Segment>
            ))
          ) : (
            <SegmentsPlaceholder />
          )}
        </Chart>
        {enrichedStages.length ? (
          viewportWidth > 600 ? (
            <Legends ref={legendsRef}>
              {enrichedStages.map((s, i) => (
                <ActiveReturnsLegend
                  key={i}
                  dotOnly={dotOnly.includes(i)}
                  index={getStagePaletteIndex(s.customIndex, s.systemStage)}
                  name={s.label}
                  value={`${s.count} (${s.percent}%)`}
                  palette={STAGE_PALETTE}
                  dim={highlightedIndex !== null && highlightedIndex !== i}
                  highlight={i === highlightedIndex}
                  highlightLeft={hoverLefts.has(i) ? hoverLefts.get(i) + 'px' : undefined}
                  onActive={() => setHighlightedIndex(i)}
                  onLeft={() => setHighlightedIndex((current) => (current === i ? null : current))}
                />
              ))}
            </Legends>
          ) : (
            <DataRows>
              {enrichedStages.map((s, i) => (
                <DataRow
                  key={i}
                  index={getStagePaletteIndex(s.customIndex, s.systemStage)}
                  name={s.label}
                  value={`${s.count} (${s.percent}%)`}
                  palette={STAGE_PALETTE}
                  highlight={i === highlightedIndex}
                  onActive={() => setHighlightedIndex(i)}
                  onLeft={() => setHighlightedIndex((current) => (current === i ? null : current))}
                />
              ))}
            </DataRows>
          )
        ) : (
          <Legends>
            <ReferencesPlaceholder>
              <FormattedMessage id="dashboard.noActiveReturns" defaultMessage="No active returns" />
            </ReferencesPlaceholder>
          </Legends>
        )}
      </Card>
    </Container>
  )
}

const Container = styled.div`
  margin-top: var(--p-space-200);
  ${ChartName} {
    margin-top: -0.2em;
  }
  margin-bottom: var(--p-space-1000);
`

const Texts = styled.a`
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  flex-flow: column;
  gap: 6px;
`

const Count = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const ChartNameAndChange = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4em;
`

const Chart = styled.div`
  margin-top: var(--p-space-200);
  width: 100%;
  height: 6px;
  position: relative;
`

const SegmentsPlaceholder = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 3px;
  background: ${CHART_PLACEHOLDER_COLOR};
`

const Segment = styled.div<{ start: number; end: number; highlighted: boolean }>`
  position: absolute;
  inset: 0;
  border-radius: 3px;
  overflow: hidden;
  pointer-events: none;
  transition: transform 0.1s ${DEFAULT_EASING};
  ${ReferenceColor} {
    pointer-events: all;
    left: ${(p) => p.start * 100}%;
    right: ${(p) => (1 - p.end) * 100}%;
  }
  ${(p) =>
    p.highlighted &&
    css`
      transform: scaleY(calc(8 / 6));
    `}
`

const Legends = styled.div`
  margin-bottom: -4px;
  display: flex;
  gap: var(--p-space-300);
  box-sizing: content-box;
  padding: calc(7px + 3px) 0 3px;
  height: var(--p-font-line-height-500);
  position: relative;
  z-index: 1;
  overflow: hidden;
`

const IconContainer = styled.span`
  margin-left: 0.4em;
  opacity: 0.5;
  vertical-align: middle;
  .Polaris-Icon {
    vertical-align: middle;
    display: inline-block;
    position: relative;
    top: -0.15em;
  }
`
