import { createSelector } from '@reduxjs/toolkit'
import { selectFormatter } from 'store/global/global.selectors'

import { ArchiveFilter } from 'gql'
import { ColumnType, SortType } from 'returns/Filters'

// const selectFilters = (s: RootState) => s.filters

export const selectAllFilterColumns = createSelector([selectFormatter], (fmt) => {
  interface iColumn {
    value: ColumnType
    label: string
  }
  const columns: iColumn[] = [
    { value: 'orderDate', label: fmt('filters.columns.orderDate') },
    { value: 'stageUpdatedDate', label: fmt('filters.columns.stageUpdatedDate') },
    { value: 'customerEmail', label: fmt('filters.columns.customerEmail') },
    { value: 'returnType', label: fmt('filters.columns.returnType') },
    { value: 'stage', label: fmt('filters.columns.stage') },
    { value: 'resolvedStatus', label: fmt('resolvedStatus.label') },
    { value: 'returnDate', label: fmt('filters.columns.returnDate') },
    { value: 'warehouseName', label: fmt('filters.columns.warehouse') },
    { value: 'trackingNumber', label: fmt('filters.columns.trackingNumber') },
    { value: 'status', label: fmt('filters.columns.status') },
    { value: 'labelCost', label: fmt('filters.columns.labelCost') },
    { value: 'deliveryStatus', label: fmt('filters.columns.deliveryStatus') },
    { value: 'deliveryDate', label: fmt('filters.columns.deliveryDate') },
    { value: 'unitQuantity', label: fmt('filters.columns.unitQuantity') },
    { value: 'returnValueAmount', label: fmt('filters.columns.returnValueAmount') },
  ]
  return columns
})

export const selectSortOptions = createSelector([selectFormatter], (fmt) => {
  interface iSortColumn {
    value: SortType
    label: string
  }
  const options: iSortColumn[] = [
    { value: '-orderDate', label: fmt('filters.sort.orderDate') },
    { value: 'orderDate', label: fmt('filters.sort.orderDateOldestFirst') },
    { value: 'stage', label: fmt('filters.sort.stage') },
    { value: '-returnDate', label: fmt('filters.sort.returnDate') },
    { value: 'returnDate', label: fmt('filters.sort.returnDateOldestFirst') },
    { value: 'warehouseName', label: fmt('filters.sort.warehouse') },
    { value: 'deliveryStatus', label: fmt('filters.sort.deliveryStatus') },
    { value: '-deliveryDate', label: fmt('filters.sort.deliveryDate') },
    { value: 'deliveryDate', label: fmt('filters.sort.deliveryDateOldestFirst') },
  ]
  return options
})

export const selectStatusFilterOptions = createSelector([selectFormatter], (fmt) => ({
  ACTIVE: { label: fmt('filters.statusFilter.active'), value: ArchiveFilter.Active },
  ARCHIVED: { label: fmt('filters.statusFilter.archive'), value: ArchiveFilter.Archived },
  BOTH: { label: fmt('filters.statusFilter.both'), value: ArchiveFilter.Both },
}))
