import { Page } from '@shopify/polaris'
import { FunctionComponent, lazy, Suspense } from 'react'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { useCommonData } from 'common/useCommonData'
import { useBillingQuery } from 'gql'

const SelectSubscriptionPlan = lazy(() => import('plans/SelectSubscriptionPlan'))

const ForceSelectSubscriptionPlan: FunctionComponent = () => {
  const fmt = _(selectFormatter)
  const [{ data }] = useCommonData(useBillingQuery, 'billing')

  return (
    <Suspense>
      <Page title={fmt('forceSubscription.pageTitle')} subtitle={fmt('forceSubscription.freeTrialExpired')}>
        <SelectSubscriptionPlan subscriptionPlans={data?.subscriptionPlans} />
      </Page>
    </Suspense>
  )
}

export default ForceSelectSubscriptionPlan
