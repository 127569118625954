import { EventHandler, FC, FocusEvent, MouseEvent, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { DEFAULT_EASING } from 'onboarding/onboarding-styles'

import { ReferenceColor } from './ReferenceColor'

type DataRowProps = {
  index?: number
  palette?: string[]
  name: ReactNode
  value: ReactNode
  url?: string
  pathname?: string
  highlight?: boolean
  onActive?: EventHandler<MouseEvent | FocusEvent>
  onLeft?: EventHandler<MouseEvent | FocusEvent>
}

export const DataRow: FC<DataRowProps> = ({
  index,
  palette,
  name,
  value,
  url,
  pathname,
  highlight,
  onActive,
  onLeft,
}) => {
  const navigate = useNavigate()
  return (
    <Link
      href={url || pathname}
      target={url ? '_blank' : undefined}
      onClick={!url && pathname ? () => navigate(pathname) : undefined}
      highlight={highlight}
      tabIndex={0}
      onMouseEnter={onActive}
      onFocus={onActive}
      onMouseLeave={onLeft}
      onBlur={onLeft}
    >
      {index !== undefined && (
        <Bullet>
          <ReferenceColor index={index} palette={palette} />
        </Bullet>
      )}
      <Name>{name}</Name>
      <Value>{value}</Value>
    </Link>
  )
}

const Link = styled.a<{ highlight?: boolean }>`
  color: inherit;
  text-decoration: none;
  margin: 0 calc(var(--p-space-400) * -1 + 1px);
  padding: 6px calc(var(--p-space-400) - 1px);
  display: flex;
  align-items: center;
  transition: background-color 0.1s ${DEFAULT_EASING};
  ${(p) =>
    p.href &&
    css`
      cursor: pointer;
    `};
  ${(p) =>
    p.highlight === undefined
      ? css`
          &:hover,
          &:focus-visible {
            background: var(--p-color-bg-surface-hover);
          }
        `
      : p.highlight &&
        css`
          background: var(--p-color-bg-surface-hover);
        `}
`

const Bullet = styled.span`
  flex: 0 0 auto;
  display: block;
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: var(--p-space-300);
`

const Name = styled.span`
  flex: 1 1 0;
  display: block;
  margin-right: var(--p-space-300);
`

const Value = styled.span`
  display: block;
  flex: 0 0 auto;
  color: var(--p-color-text-secondary);
`
