import { DashboardQuery } from 'gql'

export const DefaultDashboardMetrics: DashboardQuery = {
  dashboardMetrics: {
    activeReturns: {
      count: 0,
      stage: [],
      __typename: 'ActiveReturnsMetricType',
    },
    returnsChart: [],
    stage: [],
    mostReturned: {
      products: [],
      variants: [],
      __typename: 'ReturnProductMetricType',
    },
    policy: [],
    reason: [],
    actionTaken: {
      exchanges: 0,
      refunds: 0,
      storeCredit: 0,
      manuallyResolved: 0,
      __typename: 'ActionTakenMetricType',
    },
    __typename: 'DashboardMetrics',
  },
}
