import { createSelector } from '@reduxjs/toolkit'
import {
  selectDefaultRefundFee,
  selectDefaultStoreCreditFee,
  selectFormatter,
  selectShop,
  selectShopCustomStages,
  selectShopFeatures,
  selectShopStages,
  selectShopSystemStages,
  selectShopifyLocations,
} from 'store/global/global.selectors'
import { RootState } from 'store/store'

import { ExchangeOrderStatus, GrapheneRestockType, ResolvedStatus, ReturnType, SystemStage } from 'gql'
import { LocaleKey } from 'intl/intl'
import { notEmpty, roundCents } from 'utils'

import { ItemGroup, LogAsInFragment, NextStepOption } from '../models'
import { RESOLVED_BADGES_BY_ID } from '../values'

const selectRMAState = (s: RootState) => s.rma!

export const selectRefundEnabled = createSelector(
  [selectShop, selectShopFeatures],
  (shop, features) => features?.includes('refund_integration') && shop.isRefundIntegrationEnabled,
)
export const selectExchangeEnabled = createSelector(
  [selectShop, selectShopFeatures],
  (shop, features) =>
    features?.includes('exchange_integration') && shop.exchangeIntegration && shop.isExchangeIntegrationEnabled,
)
export const selectCreditEnabled = createSelector(
  [selectShop, selectShopFeatures],
  (shop, features) =>
    features?.includes('store_credit_integration') &&
    shop.storeCreditIntegration &&
    shop.isStoreCreditIntegrationEnabled,
)
export const selectRestockEnabled = createSelector(
  [selectShop, selectShopFeatures],
  (shop, features) => features?.includes('restock_integration') && shop.isRestockIntegrationEnabled,
)

export const selectMode = createSelector([selectRMAState], (s) => s.mode)

export const selectStageIdUpdatingTo = createSelector([selectRMAState], (s) => s.stageUpdatingTo)
export const selectStageUpdatingTo = createSelector(
  [selectStageIdUpdatingTo, selectShopSystemStages, selectShopCustomStages],
  (id, systemStages, customStages) =>
    id !== null ? systemStages?.find((s) => s.id === id) || customStages?.find((s) => s.id === id) : null,
)
export const selectSystemStageUpdatingTo = createSelector([selectStageUpdatingTo], (s) => s?.systemStage)
export const selectUpdatingToCustomStage = createSelector(
  [selectStageIdUpdatingTo, selectShopCustomStages],
  (stageIdUpdatingTo, customStages) => !!customStages?.find((cs) => cs.id === stageIdUpdatingTo),
)
export const selectShowTestReturnBanner = createSelector([selectRMAState], (s) => s.showTestReturnBanner)
export const selectShowDeleteModal = createSelector([selectRMAState], (s) => s.showDeleteModal)
export const selectSelectionEnabled = createSelector([selectRMAState], (s) => s.selectionEnabled)
export const selectSelectedIds = createSelector([selectRMAState], (s) => s.selected)
export const selectUnselectedIds = createSelector([selectRMAState], (s) =>
  s.rma?.items.map((i) => i.id).filter((id) => !s.selected.includes(id)),
)
export const selectIdSelected = (id: string) => createSelector([selectSelectedIds], (selected) => selected.includes(id))
export const selectSelectedCount = createSelector([selectSelectedIds], (selected) => selected.length)
export const selectGalleryItemId = createSelector([selectRMAState], (s) => s.gallery.id)
export const selectGalleryIndex = createSelector([selectRMAState], (s) => s.gallery.index)

export const selectRMA = createSelector([selectRMAState], (s) => s.rma)
export const selectRMAId = createSelector([selectRMA], (rma) => rma?.id)
export const selectRMANumber = createSelector([selectRMA], (rma) => rma?.rmaNumber)
export const selectRMAOrderNumber = createSelector([selectRMA], (rma) => rma?.shopifyOrderNumber)
export const selectRMAOrderURL = createSelector([selectRMA], (rma) => rma?.shopifyOrderUrl)
export const selectTestReturn = createSelector([selectRMA], (rma) => rma?.testMode)
export const selectAdminReturn = createSelector([selectRMA], (rma) => rma?.adminReturn)
export const selectRMAArchived = createSelector([selectRMA], (rma) => rma?.isArchived)
export const selectRMAStageId = createSelector([selectRMA], (rma) => rma?.statusId)
export const selectRMASystemStage = createSelector(
  [selectRMAStageId, selectShopSystemStages],
  (stageId, systemStages) => systemStages?.find((s) => s.id === stageId)?.systemStage,
)
export const selectHasValidCustomerPaymentPending = createSelector(
  [selectRMA],
  (rma) => rma?.hasValidCustomerPaymentPending,
)
export const selectRMAStage = createSelector([selectRMAStageId, selectShopStages], (id, stages) => {
  return id !== undefined && id !== null ? stages?.find((s) => s.id === id) : id
})
export const selectRMAApproved = createSelector(
  [selectRMASystemStage],
  (systemStage) =>
    !systemStage ||
    systemStage === SystemStage.AutomaticallyApproved ||
    systemStage === SystemStage.Approved ||
    systemStage === SystemStage.Received,
)
export const selectRMACreated = createSelector([selectRMA], (rma) => rma?.createdAt)
export const selectRestockingFeeType = createSelector([selectRMAState], (s) => s.rma?.restockingFeeType)
export const selectRMADefaultRefundFee = createSelector([selectRMA], (rma) => rma?.defaultRefundFee)
export const selectRMADefaultStoreCreditFee = createSelector([selectRMA], (rma) => rma?.defaultStoreCreditFee)
export const selectRestockingFeeCharged = createSelector([selectRMA], (rma) =>
  rma && rma.restockingFeeCharged !== undefined && rma.restockingFeeCharged !== null && !isNaN(rma.restockingFeeCharged)
    ? parseFloat(rma.restockingFeeCharged)
    : 0,
)
export const selectHandlingFeeCharged = createSelector([selectRMA], (rma) =>
  rma && rma.handlingFeeCharged !== undefined && rma.handlingFeeCharged !== null && !isNaN(rma.handlingFeeCharged)
    ? parseFloat(rma.handlingFeeCharged)
    : 0,
)
export const selectStoreCreditIncentivePercentage = createSelector(
  [selectShop],
  (shop) => shop.storeCreditIncentivePercentage,
)
export const selectIsStoreCreditIncentiveEnabled = createSelector(
  [selectShop],
  (shop) => shop.isStoreCreditIncentiveEnabled,
)

export const selectMaxStoreCreditIncentiveAmount = createSelector([selectShop], (shop) =>
  shop.maxStoreCreditIncentiveAmount ? parseFloat(shop.maxStoreCreditIncentiveAmount) : 0,
)
export const selectMinStoreCreditIncentiveAmount = createSelector([selectShop], (shop) =>
  shop.minStoreCreditIncentiveAmount ? parseFloat(shop.minStoreCreditIncentiveAmount) : 0,
)
export const selectIsStoreCreditIncentiveRoundToNearest = createSelector(
  [selectShop],
  (shop) => shop.isStoreCreditIncentiveRoundToNearest,
)
export const selectStoreCreditIncentiveType = createSelector([selectShop], (shop) => shop.storeCreditIncentiveType)

export const selectNextRMA = createSelector([selectRMA], (rma) => rma?.nextReturn)
export const selectPreviousRMA = createSelector([selectRMA], (rma) => rma?.previousReturn)

const selectRMALogs = createSelector([selectRMA], (rma) => rma?.logs.filter(notEmpty))

const selectRMASortedLogs = createSelector([selectRMALogs], (logs) =>
  logs?.sort((a, b) => new Date(b.moment).getTime() - new Date(a.moment).getTime()),
)

const selectRMALogsByDate = createSelector([selectRMASortedLogs], (sortedLogs): Map<string, LogAsInFragment[]> => {
  const logsByDate = new Map<string, LogAsInFragment[]>()
  sortedLogs?.forEach((logItem) => {
    const date = new Date(logItem.moment)
    const key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    const logItemsForDate = logsByDate.get(key) || []
    logItemsForDate.push(logItem)
    logsByDate.set(key, logItemsForDate)
  })
  return logsByDate
})

export const selectRMADatedLogs = createSelector([selectRMALogsByDate], (logsByDate) =>
  Array.from(logsByDate.keys()).map((date) => ({
    date: date,
    logs: logsByDate.get(date)!,
  })),
)

export const selectRMAItems = createSelector([selectRMA], (rma) => rma?.items)
export const selectRMAPresentmentCurrency = createSelector([selectRMAItems], (items) => items?.[0].presentmentCurrency)
export const selectItemsWithExchangeOrders = createSelector([selectRMAItems], (items) =>
  items?.filter((item) => item.exchangeOrder && item.exchangeItem),
)
export const selectItemsWithConsolidatedOrders = createSelector([selectItemsWithExchangeOrders], (items) =>
  items?.filter((item) => item.exchangeOrder?.consolidatedOrderExchange),
)
export const selectRMAUsesConsolidatedExchanges = createSelector(
  [selectItemsWithConsolidatedOrders],
  (items) => !!items?.length,
)
export const selectItemsPendingRestock = (includeMissing = false) =>
  createSelector([selectRMAItems], (items) =>
    items?.filter(notEmpty).filter((i) => !i.isRestocked && !i.skipRestock && (!i.isMissing || includeMissing)),
  )
export const selectSelectedItems = createSelector([selectRMAItems, selectSelectedIds], (items, ids) =>
  items?.filter(notEmpty).filter((i) => ids.includes(i.id)),
)
export const selectSelectedExchangeOrders = createSelector([selectSelectedItems], (selectedItems) =>
  selectedItems?.map((i) => i.exchangeOrder).filter(notEmpty),
)
export const selectSelectedExchangeOrderIds = createSelector([selectSelectedExchangeOrders], (exchangeOrders) =>
  exchangeOrders?.map((eo) => eo.id).filter(notEmpty),
)
export const selectSelectedAmount = createSelector([selectSelectedItems], (items) =>
  items?.filter(notEmpty).reduce((result, i) => result + i.presentmentDiscountedAmount, 0),
)
export const selectDifferentPolicySelected = (policy: ReturnType) =>
  createSelector([selectSelectedItems], (items) => !!items?.find((i) => i.returnType !== policy))
export const selectSelectedDefaultRMARestockingFee = (
  operation: ReturnType.Refund | ReturnType.Credit | 'resolve-manually',
) =>
  createSelector(
    [
      selectSelectedItems,
      selectRestockingFeeType,
      selectRMADefaultRefundFee,
      selectDefaultRefundFee,
      selectRMADefaultStoreCreditFee,
      selectDefaultStoreCreditFee,
    ],
    (
      selectedItems,
      restockingFeeType,
      defaultRMARefundFee,
      defaultShopRefundFee,
      defaultRMACreditFee,
      defaultShopCreditFee,
    ) => {
      const restockingFees = selectedItems
        ?.filter((item) => item.restockingFee !== undefined && item.restockingFee !== null)
        .map((item) => parseFloat(item.restockingFee))
        .filter((fee) => !isNaN(fee))
      if (restockingFeeType && restockingFees?.length)
        return roundCents(
          restockingFees.reduce(
            (previous, fee) =>
              restockingFeeType === GrapheneRestockType.OncePerReturn ? Math.max(previous, fee) : previous + fee,
            0,
          ),
        )
      if (operation === ReturnType.Refund) {
        if (defaultRMARefundFee !== undefined && defaultRMARefundFee !== null && !isNaN(defaultRMARefundFee))
          return parseFloat(defaultRMARefundFee)
        if (defaultShopRefundFee !== undefined && defaultShopRefundFee !== null && !isNaN(defaultShopRefundFee))
          return parseFloat(defaultShopRefundFee)
      }
      if (operation === ReturnType.Credit) {
        if (defaultRMACreditFee !== undefined && defaultRMACreditFee !== null && !isNaN(defaultRMACreditFee))
          return parseFloat(defaultRMACreditFee)
        if (defaultShopCreditFee !== undefined && defaultShopCreditFee !== null && !isNaN(defaultShopCreditFee))
          return parseFloat(defaultShopCreditFee)
      }
      return 0
    },
  )
export const selectSelectedDefaultRMAHandlingFee = createSelector([selectSelectedItems], (selectedItems) => {
  const handlingFees = selectedItems
    ?.filter((item) => item.handlingFee !== undefined && item.handlingFee !== null)
    .map((item) => parseFloat(item.handlingFee))
    .filter((fee) => !isNaN(fee))
  if (handlingFees?.length) return roundCents(handlingFees.reduce((previous, fee) => Math.max(previous, fee), 0))
  return 0
})

export const selectRestockableSelected = createSelector(
  [selectRestockEnabled, selectSelectedItems],
  (enabled, items) => (enabled && items ? items.filter((i) => !i.isRestocked).length : 0),
)
export const selectItemGroupsById = createSelector([selectRMAItems], (items) => {
  if (!items) return items
  const groups = new Map<string, ItemGroup>()
  for (const item of items) {
    const groupingCriteria = [
      item.shopifyVariantId,
      item.returnType,
      item.returnReasonId,
      item.isRestocked,
      item.skipRestock,
      !!item.resolvedAt,
      item.isResolvedManually,
      item.isMissing,
      item.storeCredit?.shopifyId,
      item.isExpired,
    ]
    const groupId = groupingCriteria.join('-')
    const group: ItemGroup = groups.get(groupId) || {
      ...item,
      id: groupId,
      ids: [],
      orders: [],
      exchangeOrder: undefined,
    }
    group.ids.push(item.id)
    if (item.exchangeOrder && !group.orders.find((o) => o.id === item.exchangeOrder!.id))
      group.orders.push(item.exchangeOrder)
    groups.set(groupId, group)
  }
  return groups
})
export const selectItemGroups = createSelector(
  [selectItemGroupsById],
  (groupsById) => groupsById && Array.from(groupsById.values()),
)
export const selectItemGroup = (id: string) =>
  createSelector([selectItemGroupsById], (groupsById) => groupsById?.get(id))
export const selectReceivedGroups = createSelector([selectItemGroups], (groups) => groups?.filter((g) => !g.isMissing))
export const selectGroupsSelected = (groupId: string) =>
  createSelector(
    [selectSelectionEnabled, selectSelectedIds, selectItemGroup(groupId)],
    (selectionEnabled, selectedIds, group) =>
      selectionEnabled && group ? group.ids.filter((id) => selectedIds.includes(id)) : [],
  )
export const selectGroupSelectedCount = (groupId: string) =>
  createSelector([selectGroupsSelected(groupId)], (selectedIds) => selectedIds?.length)
export const selectGroupIsSelected = (groupId: string) =>
  createSelector(
    [selectSelectionEnabled, selectGroupSelectedCount(groupId), selectItemGroup(groupId)],
    (selectable, selectedCount, group) =>
      selectable &&
      group &&
      (selectedCount === 0 ? false : selectedCount < group.ids.length ? ('indeterminate' as const) : true),
  )
export const selectUnresolvedGroups = (actuallyUnresolved = false) =>
  createSelector([selectReceivedGroups], (receivedGroups) =>
    receivedGroups?.filter(actuallyUnresolved ? (g) => !g.actuallyResolvedAt : (g) => !g.resolvedAt),
  )
export const selectResolvedGroups = (actuallyResolved = false) =>
  createSelector([selectItemGroups], (receivedGroups) =>
    receivedGroups?.filter(actuallyResolved ? (g) => !!g.actuallyResolvedAt : (g) => !!g.resolvedAt),
  )
export const selectDismissedGroups = createSelector([selectItemGroups], (groups) =>
  groups?.filter((g) => (g.isMissing || g.isExpired) && !g.actuallyResolvedAt),
)
export const selectPendingResolutionGroups = createSelector([selectUnresolvedGroups()], (unresolved) =>
  unresolved?.filter((g) => !g.isExpired),
)
export const selectRefundableGroups = createSelector([selectUnresolvedGroups()], (unresolvedGroups) =>
  unresolvedGroups?.filter((g) => g.returnType === 'REFUND'),
)
export const selectExchangeableGroups = createSelector([selectUnresolvedGroups()], (unresolvedGroups) =>
  unresolvedGroups?.filter((g) => g.returnType === 'EXCHANGE'),
)
export const selectExchangeModeGroups = createSelector([selectReceivedGroups], (unresolvedGroups) =>
  unresolvedGroups?.filter(
    (g) =>
      (g.returnType === 'EXCHANGE' && !g.resolvedAt && g.orders.filter((o) => !o.consolidatedOrderExchange).length) ||
      !!g.orders.filter((o) => o.consolidatedOrderExchange && o.status !== ExchangeOrderStatus.Completed).length,
  ),
)
export const selectDraftExchangeModeGroups = createSelector([selectExchangeModeGroups], (exchangeModeGroups) =>
  exchangeModeGroups?.filter((g) => g.orders.find((o) => !o.consolidatedOrderExchange)),
)
export const selectCreditableGroups = createSelector([selectUnresolvedGroups()], (unresolvedGroups) =>
  unresolvedGroups?.filter((g) => g.returnType === 'CREDIT'),
)
export const selectRestockableGroups = (includeExpired = false) =>
  createSelector([selectReceivedGroups], (receivedGroups) =>
    receivedGroups?.filter((g) => !g.isRestocked && (includeExpired || !g.isExpired)),
  )
export const selectUnresolvedCardGroups = createSelector(
  [selectMode, selectPendingResolutionGroups, selectRestockableGroups()],
  (mode, pendingResolution, restockable) =>
    mode !== 'restock'
      ? pendingResolution
      : pendingResolution?.filter((pending) => restockable?.find((restockable) => pending.id === restockable.id)),
)
export const selectHasUnresolvedGroups = createSelector([selectUnresolvedGroups()], (groups) => !!groups?.length)
export const selectHasRefundableGroups = createSelector([selectRefundableGroups], (groups) => !!groups?.length)
export const selectHasExchangeableGroups = createSelector([selectExchangeableGroups], (groups) => !!groups?.length)
export const selectHasExchangeModeGroups = createSelector([selectExchangeModeGroups], (groups) => !!groups?.length)
export const selectHasCreditableGroups = createSelector([selectCreditableGroups], (groups) => !!groups?.length)
export const selectHasRestockableGroups = (includeExpired: boolean) =>
  createSelector([selectRestockableGroups(includeExpired)], (groups) => !!groups?.length)
export const selectStageActions = createSelector(
  [selectRMASystemStage, selectShopSystemStages],
  (currentSystemStage, systemStages) => {
    const passedApproval =
      currentSystemStage === SystemStage.Approved ||
      currentSystemStage === SystemStage.AutomaticallyApproved ||
      currentSystemStage === SystemStage.Received ||
      !currentSystemStage

    const getStageDisabled = (stage: SystemStage) => {
      if (stage === currentSystemStage) return true
      switch (stage) {
        case SystemStage.PendingApproval:
        case SystemStage.Rejected:
          return passedApproval
      }
    }

    return systemStages
      ?.filter((s) => s.systemStage !== SystemStage.AutomaticallyApproved)
      .map((s) => ({
        id: s.id,
        content: s.label,
        active: currentSystemStage === s.id,
        disabled: getStageDisabled(s.systemStage!),
      }))
  },
)
export const selectCustomStageActions = createSelector(
  [selectRMAStageId, selectShopCustomStages],
  (currentStageId, customStages) => {
    return customStages?.map((s) => ({
      id: s.id,
      content: s.label,
      active: currentStageId === s.id,
      disabled: currentStageId === s.id,
    }))
  },
)

export const selectStageNextSteps = createSelector([selectShopSystemStages, selectFormatter], (systemStages, fmt) => {
  const steps = systemStages?.map((s) => ({
    type: 'stage' as const,
    id: s.id,
    content: fmt('sidebar.updateToStage', { stage: s.label }),
  }))
  return steps
})
export const selectStageNextStepsById = createSelector([selectStageNextSteps], (stageNextSteps) => ({
  ...stageNextSteps?.reduce(
    (result, step) => ({ ...result, [step.id]: step }),
    {} as Record<string, NextStepOption & { type: 'stage' }>,
  ),
}))
export const selectResolvedStatus = createSelector([selectFormatter, selectRMA], (fmt, rma) => {
  const resolvedStatus: ResolvedStatus = rma?.resolvedStatusV2 ?? ResolvedStatus.Unresolved
  return { ...RESOLVED_BADGES_BY_ID[resolvedStatus], name: fmt(`resolvedStatus.${resolvedStatus}` as LocaleKey) }
})
export const selectGalleryImages = createSelector(
  [selectGalleryItemId, selectRMA],
  (id, rma) => id && rma?.items.find((i) => i.id === id)?.images,
)
export const selectCurrentGalleryImage = createSelector(
  [selectGalleryIndex, selectGalleryImages],
  (i, images) => images?.[i],
)

export const selectMultipleLocations = createSelector([selectShopifyLocations], (warehouses) =>
  warehouses ? warehouses.length > 1 : false,
)

export const selectRMALogsWithGroupedItems = createSelector(
  [selectRMADatedLogs, selectItemGroups],
  (datedLogs, groups) =>
    datedLogs.map((day) => ({
      ...day,
      logs: day.logs
        .map((log) => {
          type ReturnItem = (typeof log)['returnItems'][number]
          type GroupedReturnItem = ReturnItem & { count: number }
          return {
            ...log,
            // Group return items by their UI groups
            returnItems: Array.from(
              log.returnItems
                .reduce((result, item) => {
                  if (!groups) return result
                  const groupId = groups.find((g) => g.ids.includes(item.id))?.id

                  let newGroupedItem: GroupedReturnItem | undefined
                  if (!groupId) newGroupedItem = { ...item, count: 1 }
                  else {
                    const existingGroupedItem = result.get(groupId)
                    if (existingGroupedItem)
                      newGroupedItem = {
                        ...existingGroupedItem,
                        count: existingGroupedItem.count + 1,
                      }
                    else
                      newGroupedItem = {
                        ...item,
                        id: groupId,
                        count: 1,
                      }
                  }

                  const newResult = new Map(result)
                  newResult.set(newGroupedItem.id, newGroupedItem)
                  return newResult
                }, new Map<string, GroupedReturnItem>())
                .values(),
            ),
          }
        })
        .map((log) => ({ ...log, showReturnItemsCount: !!log.returnItems.find((i) => i.count > 1) })),
    })),
)

export const selectQuickHighlightGroup = createSelector([selectRMAState], (s) => s.quickHighlightGroup)
export const selectQuickHighlightExchangeGroup = createSelector([selectRMAState], (s) => s.quickHighlightExchangeGroup)

export const selectConsolidatedExchanges = createSelector([selectItemsWithConsolidatedOrders], (items) =>
  items?.map((item) => ({
    itemId: item.id,
    exchangeItem: item.exchangeItem!,
    order: item.exchangeOrder!,
  })),
)
export const selectIncompleteConsolidatedExchanges = createSelector([selectConsolidatedExchanges], (exchanges) =>
  exchanges?.filter((e) => e.order.status !== ExchangeOrderStatus.Completed),
)
export const selectHasIncompleteConsolidatedExchanges = createSelector(
  [selectIncompleteConsolidatedExchanges],
  (incompleteExchanges) => incompleteExchanges && !!incompleteExchanges.length,
)
export const selectConsolidatedExchangeGroupsById = createSelector([selectConsolidatedExchanges], (exchanges) => {
  if (!exchanges) return exchanges
  type ExchangeData = (typeof exchanges)[number]
  type GroupedExchange = ExchangeData['exchangeItem'] & {
    groupId: string
    itemIds: string[]
    orders: ExchangeData['order'][]
  }
  const groups = new Map<string, GroupedExchange>()
  for (const exchange of exchanges) {
    const groupingCriteria = [exchange.exchangeItem.exchangeVariantId, ExchangeOrderStatus.Completed]
    const groupId = groupingCriteria.join('-')
    const group = groups.get(groupId) || {
      ...exchange.exchangeItem,
      groupId,
      itemIds: [] as string[],
      orders: [] as ExchangeData['order'][],
    }
    group.itemIds.push(exchange.itemId)
    group.orders.push(exchange.order)
    groups.set(groupId, group)
  }
  return groups
})
export const selectRMAExchangeBalance = createSelector([selectRMA], (rma) => +rma?.exchangeBalanceDue)
export const selectRMAExchangeBalancePaid = createSelector([selectRMA], (rma) => !!rma?.exchangeBalancePaidAt)
export const selectConsolidatedExchangeGroups = createSelector(
  [selectConsolidatedExchangeGroupsById],
  (groupsById) => groupsById && Array.from(groupsById.values()),
)
export const selectConsolidatedExchangeGroup = (groupId: string) =>
  createSelector([selectConsolidatedExchangeGroupsById], (exchanges) => exchanges?.get(groupId))

export const selectConsolidatedExchangesSelectable = createSelector([selectMode], (mode) => mode === 'exchange')

export const selectConsolidatedExchangeSelected = (groupId: string) =>
  createSelector(
    [selectConsolidatedExchangesSelectable, selectSelectedIds, selectConsolidatedExchangeGroup(groupId)],
    (selectable, selectedIds, group) =>
      selectable && group ? group.itemIds.filter((id) => selectedIds.includes(id)) : [],
  )
export const selectConsolidatedExchangeGroupSelectedCount = (groupId: string) =>
  createSelector([selectConsolidatedExchangeSelected(groupId)], (selectedIds) => selectedIds.length)
export const selectConsolidatedExchangeGroupIsSelected = (groupId: string) =>
  createSelector(
    [
      selectConsolidatedExchangesSelectable,
      selectConsolidatedExchangeGroupSelectedCount(groupId),
      selectConsolidatedExchangeGroup(groupId),
    ],
    (selectable, selectedCount, group) =>
      selectable &&
      group &&
      (selectedCount === 0 ? false : selectedCount < group.itemIds.length ? ('indeterminate' as const) : true),
  )
export const selectPendingWork = createSelector(
  [
    selectRMASystemStage,
    selectRestockEnabled,
    selectItemsPendingRestock(),
    selectExchangeEnabled,
    selectRMAUsesConsolidatedExchanges,
    selectHasExchangeModeGroups,
  ],
  (systemStage, restockEnabled, pendingRestockItems, exchangeEnabled, consolidated, hasExchangeModeGroups) => {
    if (systemStage === SystemStage.Rejected) return false
    const pendingReceiveStage = !!systemStage && systemStage !== SystemStage.Received
    const pendingRestock = restockEnabled && !!pendingRestockItems?.length
    const pendingExchanges = exchangeEnabled && consolidated && hasExchangeModeGroups
    return pendingReceiveStage || pendingRestock || pendingExchanges
  },
)

export const selectOtherNextSteps = createSelector([selectFormatter], (fmt) => [
  {
    type: 'other' as const,
    id: 'archive',
    content: fmt('global.archive'),
  },
  {
    type: 'other' as const,
    id: 'unarchive',
    content: fmt('global.unarchive'),
  },
  {
    type: 'other' as const,
    id: 'delete',
    content: fmt('return.deleteReturn'),
  },
])

export const selectOtherNextStepsById = createSelector([selectOtherNextSteps], (ns) =>
  ns.reduce(
    (result, step) => ({ ...result, [step.id]: step }),
    {} as Record<(typeof ns)[number]['id'], NextStepOption & { type: 'other' }>,
  ),
)

export const selectConsolidatedRMARequiresReimbursement = createSelector(
  [selectRMAItems, selectRMAUsesConsolidatedExchanges],
  (items, consolidated) =>
    consolidated &&
    items &&
    items.reduce(
      (total, item) =>
        total + +(item.presentmentDiscountedAmount ?? 0) - (item.exchangeItem?.presentmentDiscountedAmount ?? 0),
      0,
    ) > 0,
)

export const selectRMAAutoResolves = createSelector(
  [selectRMAUsesConsolidatedExchanges, selectConsolidatedRMARequiresReimbursement],
  (consolidated, requiresReimbursement) => consolidated && !requiresReimbursement,
)

export const selectResolveActions = createSelector(
  [
    selectRefundEnabled,
    selectExchangeEnabled,
    selectCreditEnabled,
    selectRestockEnabled,
    selectRMAAutoResolves,
    selectMode,
    selectHasUnresolvedGroups,
    selectHasExchangeModeGroups,
    selectHasRestockableGroups(true),
    selectRMAApproved,
    selectFormatter,
  ],
  (
    refundEnabled,
    exchangeEnabled,
    creditEnabled,
    restockEnabled,
    autoResolves,
    mode,
    hasUnresolved,
    hasExchangeMode,
    hasRestockable,
    rmaApproved,
    fmt,
  ) => {
    return [
      refundEnabled
        ? {
            id: 'refund' as const,
            content: fmt('resolveOptions.refund'),
            active: mode === 'refund',
            disabled: !rmaApproved || !hasUnresolved || autoResolves,
          }
        : null,
      exchangeEnabled
        ? {
            id: 'exchange' as const,
            content: fmt('global.releaseExchanges.capitalized'),
            active: mode === 'exchange',
            disabled: !rmaApproved || !hasExchangeMode,
          }
        : null,
      creditEnabled
        ? {
            id: 'credit' as const,
            content: fmt('resolveOptions.credit'),
            active: mode === 'credit',
            disabled: !rmaApproved || !hasUnresolved || autoResolves,
          }
        : null,
      {
        id: 'resolve' as const,
        content: fmt('resolveOptions.resolve'),
        active: mode === 'resolve',
        disabled: !hasUnresolved || autoResolves,
      },
      restockEnabled
        ? {
            id: 'restock' as const,
            content: fmt('resolveOptions.restock'),
            active: mode === 'restock',
            disabled: !rmaApproved || !hasRestockable,
          }
        : null,
    ].filter(notEmpty)
  },
)
