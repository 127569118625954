import { FC, useMemo, lazy } from 'react'
import { useLocation } from 'react-router-dom'

import { isStandalone } from 'common/isStandalone'

const Navigation = lazy(() => import('./Navigation'))

export const NavigationLoader: FC = () => {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const embedded = useMemo(() => !isStandalone(), [])
  const showNavBar = !embedded || query.get('nav_bar') === 'true'
  return <>{showNavBar && <Navigation />}</>
}
