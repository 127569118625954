import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'
import { selectDataLoaded } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { PageRevealer } from 'common/PageRevealer'
import { useCommonData } from 'common/useCommonData'
import { usePortalSettingsQuery } from 'gql'
import { SkeletonSettings } from 'settings/SkeletonSettings'

const PortalCustomizerPage = lazy(() => import('./PortalCustomizerPage'))

export const PortalCustomizerPageLoader: FC = () => {
  const [query] = useCommonData(usePortalSettingsQuery, 'portal')
  const loaded = _(selectDataLoaded('portal'))

  return (
    <>
      {(query.fetching || query.stale) && <Loading />}
      {!loaded && <SkeletonSettings />}
      <PageRevealer reveal={loaded}>
        <PortalCustomizerPage />
      </PageRevealer>
    </>
  )
}
