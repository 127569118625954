import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToElement() {
  const location = useLocation()

  useEffect(() => {
    const elementId = location.hash.replace('#', '')
    const element = document.getElementById(elementId)

    if (element) element.scrollIntoView({ behavior: 'smooth' })
  }, [location])

  return null
}

export default ScrollToElement
