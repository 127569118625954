import { FC } from 'react'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { DashboardQuery } from 'gql'

import { DonutChart } from './DonutChart'

type ReasonSlice = Exclude<Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['reason'], undefined | null>
type ReasonChartProps = { data: ReasonSlice }

export const ReasonChart: FC<ReasonChartProps> = ({ data }) => {
  const fmt = _(selectFormatter)
  const enrichedData = data.map((reason) => ({ name: reason!.label || '', count: reason!.count! }))
  return <DonutChart name={fmt('global.returnReason')} data={enrichedData} />
}
