import '@shopify/app-bridge'
import { History, Redirect } from '@shopify/app-bridge/actions'
import { refreshTraceId } from 'graphQLClient'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { getShopifyApp } from 'utils/shopify'

export const ShopifyNavigationSync: FC = () => {
  const app = getShopifyApp()
  const navigate = useNavigate()

  const [history, setHistory] = useState<History.History>()
  useEffect(() => {
    if (app) {
      setHistory(History.create(app))
      return app.subscribe(Redirect.Action.APP, (data) => {
        navigate(data.path)
      })
    }
  }, [app, navigate])

  const location = useLocation()
  useEffect(() => {
    history?.dispatch(History.Action.PUSH, location.pathname)
    refreshTraceId(location.pathname)
  }, [history, location.pathname])

  return <></>
}
