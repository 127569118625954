import { ChoiceList, DatePicker, Range } from '@shopify/polaris'
import { differenceInMinutes, endOfDay, format, startOfDay, subDays } from 'date-fns'
import { FunctionComponent, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Formatter, selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { toNumber } from 'utils'

const generateRange = (days: string) => {
  const end = endOfDay(new Date())
  const start = startOfDay(subDays(end, toNumber(days)))
  return { start, end }
}

export const CUSTOM_DATE = 'CUSTOM'
export const getDateFilterOptions = (fmt: Formatter) => ({
  '1': { label: fmt('dateFilter.today'), value: '1' },
  '7': { label: fmt('dateFilter.last7Days'), value: '7' },
  '30': { label: fmt('dateFilter.last30Days'), value: '30' },
  '90': { label: fmt('dateFilter.last90Days'), value: '90' },
  '365': { label: fmt('dateFilter.last12Months'), value: '365' },
  'CUSTOM': { label: fmt('dateFilter.custom'), value: CUSTOM_DATE },
})
export type IDateFilterOptions = keyof ReturnType<typeof getDateFilterOptions>
export interface IDateFilter {
  since: null | IDateFilterOptions
  start: Date
  end: Date
}

export const getDateLabel = (fmt: Formatter, prefix: string, theDate: IDateFilter) => {
  const { since, start, end } = theDate
  let label = ''
  if (since) {
    label = getDateFilterOptions(fmt)[since].label
    if (since === CUSTOM_DATE) {
      label = `${format(start, 'PP')}`
      if (differenceInMinutes(end, start) >= 24 * 60) {
        label += ` - ${format(end, 'PP')}`
      }
    }
  }
  return `${prefix}: ${label}`
}

interface Props {
  currentValue: IDateFilter
  setValue: (v: IDateFilter) => void
}

export const DateFilter: FunctionComponent<Props> = ({ currentValue, setValue }) => {
  const fmt = _(selectFormatter)
  const { end } = currentValue
  const [viewMonth, setViewMonth] = useState({
    month: end.getMonth(),
    year: end.getFullYear(),
  })

  const handleMonthChange = useCallback((month: number, year: number) => setViewMonth({ month, year }), [])

  const saveNewDate = (selected: IDateFilterOptions[]) => {
    const since: IDateFilterOptions = selected[0]
    const { start, end } = currentValue
    let range = { start, end }
    if (since !== CUSTOM_DATE) {
      range = generateRange(since)
    }
    const newDate = { since, ...range }
    setValue(newDate)
  }

  const setCustomDate = (date: Range) => {
    const newDate = { since: currentValue.since, ...date }
    setValue(newDate)
  }

  return (
    <div>
      <ChoiceList
        name="filterByReturnDate"
        title={<FormattedMessage id="dateFilter.returnDate" defaultMessage="Return Date" />}
        titleHidden
        choices={Object.values(getDateFilterOptions(fmt))}
        selected={currentValue.since ? [currentValue.since] : []}
        onChange={saveNewDate}
      />
      {currentValue.since === CUSTOM_DATE && (
        <div style={{ marginTop: 8 }}>
          <DatePicker
            month={viewMonth.month}
            year={viewMonth.year}
            onChange={setCustomDate}
            onMonthChange={handleMonthChange}
            selected={currentValue}
            allowRange
          />
        </div>
      )}
    </div>
  )
}
