import { Loading } from '@shopify/polaris'
import { FC, lazy } from 'react'

import { useCommonData } from 'common/useCommonData'

const HelpAndSupportPage = lazy(() => import('./HelpAndSupportPage'))

export const HelpAndSupportPageLoader: FC = () => {
  const [query] = useCommonData()

  return (
    <>
      {(query.fetching || query.stale) && <Loading />}
      <HelpAndSupportPage />
    </>
  )
}
