import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { RootState, store } from './store'

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>
export const useAppDispatch = () => useDispatch<AppThunkDispatch>()
export const _: TypedUseSelectorHook<RootState> = useSelector

export type AppDispatch = typeof store.dispatch
