import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { DashboardQuery } from 'gql'
import { notEmpty } from 'utils'

import { DonutChart } from './DonutChart'

type ActionTakenSlice = Exclude<
  Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['actionTaken'],
  undefined | null
>
type ActionTakenChartProps = {
  data?: ActionTakenSlice
}

export const ActionTakenChart: FC<ActionTakenChartProps> = ({ data }) => {
  const fmt = _(selectFormatter)
  const enrichedData = [
    data?.refunds
      ? {
          name: fmt(`returnType.REFUND.done`),
          count: data.refunds,
        }
      : null,
    data?.exchanges
      ? {
          name: fmt(`returnType.EXCHANGE.done`),
          count: data.exchanges,
        }
      : null,
    data?.storeCredit
      ? {
          name: fmt(`returnType.CREDIT.done`),
          count: data.storeCredit,
        }
      : null,
    data?.manuallyResolved
      ? {
          name: fmt('rma.resolvedManually'),
          count: data.manuallyResolved,
        }
      : null,
  ].filter(notEmpty)
  return (
    <DonutChart name={<FormattedMessage id="global.actionTaken" defaultMessage="Action Taken" />} data={enrichedData} />
  )
}
