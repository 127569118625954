import { Card, Layout, Page } from '@shopify/polaris'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { selectFormatter, selectShop, selectShopHasFeature } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { Rule } from 'gql'

import RuleEditForm from './RuleEditForm'

interface RulesPageProps {
  rule?: Rule
}

const RulesPage: FC<RulesPageProps> = ({ rule }) => {
  const fmt = _(selectFormatter)
  const navigate = useNavigate()
  const shop = _(selectShop)
  const showAdjustWindowAction = !!_(selectShopHasFeature('adjust_return_windows'))
  const showApprovalActions = shop.useApprovals

  return (
    <Page
      title={rule ? fmt('rules.editRule') : fmt('rules.createRule')}
      backAction={{ content: fmt('global.settings'), onAction: () => navigate('/config-settings/rules') }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <RuleEditForm
              rule={rule}
              showAdjustWindowAction={showAdjustWindowAction}
              showApprovalActions={showApprovalActions}
              onComplete={() => navigate('/config-settings/rules')}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default RulesPage
export const RulesEditPage = RulesPage
