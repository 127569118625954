import React from 'react'

import { LOCALSTORAGE_IS_AVALAILABLE } from 'config'

export function useStickyState<Type>(key: string, defaultValue: Type): [Type, (value: Type) => void] {
  const [value, setValue] = React.useState<Type>(() => {
    let stickyValue = null
    if (LOCALSTORAGE_IS_AVALAILABLE) {
      stickyValue = window.localStorage.getItem(key)
    }
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
  })
  React.useEffect(() => {
    if (LOCALSTORAGE_IS_AVALAILABLE) {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  }, [key, value])
  return [value, setValue]
}
