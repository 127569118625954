import { FC } from 'react'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { DashboardQuery } from 'gql'

import { DonutChart } from './DonutChart'

type PolicySlice = Exclude<Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['policy'], undefined | null>
type PolicyChartProps = { data: PolicySlice }

export const PolicyChart: FC<PolicyChartProps> = ({ data }) => {
  const fmt = _(selectFormatter)
  const enrichedData = data.map((policy) => ({
    name: fmt(`returnType.${policy!.type}.name` as any),
    count: policy!.count!,
  }))
  return <DonutChart name={fmt('global.returnPolicy')} data={enrichedData} />
}
