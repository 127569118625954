import { useEffect, useRef, useState } from 'react'

export const useElementWidth = <T extends HTMLElement>() => {
  const elementRef = useRef<T>(null)
  const [width, setWidth] = useState<number | undefined>()
  useEffect(() => {
    const element = elementRef.current
    if (element) {
      const updateWidth = () => setWidth(element.offsetWidth)
      updateWidth()
      const resizeObserver = new ResizeObserver(updateWidth)
      resizeObserver.observe(element)
      return () => resizeObserver.disconnect()
    }
  }, [elementRef.current])
  return [elementRef, width] as const
}
