import { format } from 'date-fns'
import LogRocket from 'logrocket'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { selectShop, selectUser } from 'store/global/global.selectors'
import { setBeaconVisible } from 'store/global/global.slice'
import { _, useAppDispatch } from 'store/hooks'
import { selectOnboardingOpen } from 'store/onboarding/onboarding.selectors'

import { useViewportWidth } from 'common/useViewportWidth'
import { BEACON_ID } from 'config'

export const Beacon: FC = () => {
  const dispatch = useAppDispatch()
  const onboardingOpen = _(selectOnboardingOpen)
  const location = useLocation()
  const viewportWidth = useViewportWidth()
  const [beaconInittialized, setBeaconInittialized] = useState(false)
  const [beaconIdentified, setBeaconIdentified] = useState(false)
  const shop = _(selectShop)
  const user = _(selectUser)

  useEffect(() => {
    if (!beaconIdentified && shop.id && !shop.isUitestShop && user && user.chatHash) {
      const data = {
        'name': `${user.firstName} ${user.lastName}`,
        'email': user.email,
        'company': shop.url,
        'signature': user.chatHash,
        'subscription-plan': shop.subscriptionPlan?.name || 'NONE',
        'shop-url': `https://${shop.url}/`,
        'shop-admin-url': `https://api.returnzap.com/admin/shops/shop/${shop.id}/`,
        'installed-at': shop.installedAt ? format(new Date(shop.installedAt), 'yyyy-MM-dd') : '',
        'free-trial-days-remaining': shop.freeTrialDaysRemaining ?? 0,
        'onboarding-completed-at': shop.onboardingCompletedAt
          ? format(new Date(shop.onboardingCompletedAt), 'yyyy-MM-dd')
          : '',
        'return-integration': !!shop.returnIntegration,
        'user-locale': user.locale ? user.locale.split('-')[0] : '',
      }
      LogRocket.identify(user.id, data)
      window.Beacon('identify', data)
      setBeaconIdentified(true)
    }
  }, [shop, user, beaconIdentified])

  useEffect(() => {
    if (shop.isUitestShop) {
      return
    }
    const onDesktop = viewportWidth > 1160
    const hideOnSettings = location.pathname.includes('/config-settings') && !onDesktop
    const shouldShowBeacon = !onboardingOpen && !hideOnSettings
    if (BEACON_ID && shouldShowBeacon) {
      if (!beaconInittialized) {
        window.Beacon('init', BEACON_ID)
        setBeaconInittialized(true)
        dispatch(setBeaconVisible(true))
      }
    } else {
      if (beaconInittialized) {
        window.Beacon('destroy')
        setBeaconInittialized(false)
        dispatch(setBeaconVisible(false))
      }
    }
  }, [shop, onboardingOpen, beaconInittialized, location, viewportWidth])
  return <></>
}

export default Beacon

// Extend the Window interface to include the Beacon API
interface BeaconMethodArguments {
  'init': [beaconId: string]
  'destroy': []
  'open': []
  'close': []
  'toggle': []
  'search': [query: string]
  'suggest': [(string | { text: string; url: string })[]]
  'article': [articleId: string, options?: { type: 'sidebar' | 'modal' }]
  'navigate': [route: string]
  'identify': [
    userObject: {
      name?: string
      email?: string
      company?: string
      jobTitle?: string
      avatar?: string
      signature?: string
      [key: string]: any // for additional custom attributes
    },
  ]
  'prefill': [
    formObject: {
      name?: string
      email?: string
      subject?: string
      text?: string
      fields?: {
        id: number
        value: string | number
      }[]
    },
  ]
  'reset': []
  'logout': [options?: { endActiveChat: boolean }]
  'config': [formObject: any] // This could be further detailed based on the specific options provided in the documentation
  'on': [event: string, callback: (...args: any[]) => void]
  'off': [event: string, callback?: (...args: any[]) => void]
  'once': [event: string, callback: (...args: any[]) => void]
  'event': [eventObject: { type: 'page-viewed'; url: string; title: string }]
  'session-data': [data: Record<string, any>]
  'show-message': [messageId: string, options?: { delay?: number; force?: boolean }]
  'info': []
}

declare global {
  interface Window {
    Beacon: <K extends keyof BeaconMethodArguments>(method: K, ...args: BeaconMethodArguments[K]) => void
  }
}
