import { Button, ButtonGroup, Card, useBreakpoints } from '@shopify/polaris'
import { ChevronDownIcon } from '@shopify/polaris-icons'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

import { DashboardQuery } from 'gql'

import { ChartName } from './ChartName'
import { DataRow } from './DataRow'
import { DataRows } from './DataRows'
import { ReferencesPlaceholder } from './ReferencesPlaceholder'

type MostReturnedSlice = Exclude<
  Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['mostReturned'],
  undefined | null
>
type MostReturnedProps = { mostReturnedData: MostReturnedSlice }

export const MostReturned: FC<MostReturnedProps> = ({ mostReturnedData }) => {
  const { lgDown, mdDown } = useBreakpoints()
  const fmt = _(selectFormatter)

  const [expanded, setExpanded] = useState(false)
  const [selected, setSelected] = useState<Exclude<keyof typeof mostReturnedData, '__typename'>>('products')

  const rows = mostReturnedData[selected]!.slice(0, expanded ? undefined : 5)
  const showExpandButton = mostReturnedData[selected]!.length > 5

  return (
    <Container columnSpan={mdDown ? 6 : lgDown ? 3 : 4}>
      <Card>
        <Header>
          <ChartName>
            <FormattedMessage id="mostReturned.title" defaultMessage="Most Returned" />
          </ChartName>
          <Options gone={!rows.length}>
            <ButtonGroup variant="segmented">
              <Button size="slim" onClick={() => setSelected('products')} pressed={selected === 'products'}>
                {fmt('global.products')}
              </Button>
              <Button size="slim" onClick={() => setSelected('variants')} pressed={selected === 'variants'}>
                {fmt('global.variants')}
              </Button>
            </ButtonGroup>
          </Options>
        </Header>
        {rows.length ? (
          <DataRows>
            {rows.map((r, i) => (
              <DataRow key={i} name={r!.name} value={`${r!.count} returned`} url={r!.url || undefined} />
            ))}
            {showExpandButton && (
              <More expanded={expanded} onClick={() => setExpanded((current) => !current)}>
                <ChevronDownIcon />
              </More>
            )}
          </DataRows>
        ) : (
          <PlaceholderContainer>
            <ReferencesPlaceholder />
          </PlaceholderContainer>
        )}
      </Card>
    </Container>
  )
}

const Container = styled.div<{ columnSpan: number }>`
  grid-column: span ${(p) => p.columnSpan};
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const Options = styled.div<{ gone: boolean }>`
  ${(p) =>
    p.gone &&
    css`
      visibility: hidden;
    `}
`

const More = styled.button<{ expanded: boolean }>`
  position: absolute;
  inset: 0;
  top: auto;
  padding: 0;
  border: 0;
  line-height: 1;
  background: transparent;
  cursor: pointer;
  color: #999999;
  &:hover,
  &:focus-visible {
    color: var(--p-color-text);
    background: var(--p-color-bg-surface-hover);
    opacity: 1;
  }
  svg {
    fill: currentColor;
    position: relative;
    top: 0;
    width: 14px;
    ${(p) =>
      p.expanded &&
      css`
        transform: scaleY(-1);
      `}
  }
`

const PlaceholderContainer = styled.div`
  margin-top: 12px;
  border-top: 1px solid var(--p-color-border-secondary);
  padding: 11px 0 0;
  margin-bottom: -4px;
`
