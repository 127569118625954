import { useEffect, useState } from 'react'

export const useViewportWidth = () => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
  useEffect(() => {
    const update = () => {
      if (window.innerWidth !== viewportWidth) {
        setViewportWidth(window.innerWidth)
      }
    }
    window.addEventListener('resize', update)
    return () => window.removeEventListener('resize', update)
  }, [])

  return viewportWidth
}
