import { Button, Icon, Select, TextField } from '@shopify/polaris'
import { MinusCircleIcon, PlusCircleIcon } from '@shopify/polaris-icons'
import { FieldDictionary } from '@shopify/react-form'
import IMask from 'imask'
import { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  selectCountryOptions,
  selectFormatter,
  selectReturnTypeOptions,
  selectShopReasons,
} from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import { selectGetValidOperators, selectRuleAttributes } from 'store/rules/rules.selectors'
import styled from 'styled-components'

import { getAttributeType } from 'myconstants'
import { notEmpty } from 'utils'

interface ConditionRowProps {
  condition: FieldDictionary<any>
  addCondition?: () => void
  removeCondition?: () => void
}

const ConditionRow: FunctionComponent<ConditionRowProps> = ({ condition, addCondition, removeCondition }) => {
  const fmt = _(selectFormatter)
  const ruleAttributes = _(selectRuleAttributes)
  const returnTypeOptions = _(selectReturnTypeOptions)
  const reasons = _(selectShopReasons)
  const countryOptions = _(selectCountryOptions)
  const getValidOperators = _(selectGetValidOperators)
  const valueType = getAttributeType(condition.attr.value)

  const dateMask = IMask.createMask({
    mask: 'Y-m-d',
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        placeholderChar: 'd',
        from: 1,
        to: 31,
        maxLength: 2,
      },
      m: {
        mask: IMask.MaskedRange,
        placeholderChar: 'm',
        from: 1,
        to: 12,
        maxLength: 2,
      },
      Y: {
        mask: IMask.MaskedRange,
        placeholderChar: 'y',
        from: 1900,
        to: 9999,
        maxLength: 4,
      },
    },
  })
  if (valueType === 'date') {
    dateMask.value = condition.value.value
  }

  return (
    <Condition>
      <Attribute>
        <Select
          label={<FormattedMessage id="conditionRow.ruleAttribute" defaultMessage="Rule Attribute" />}
          name="attr"
          options={Object.values(ruleAttributes)}
          {...condition.attr}
        />
      </Attribute>
      <Operation>
        <Select
          label={<FormattedMessage id="conditionRow.condition" defaultMessage="Condition" />}
          name="op"
          options={getValidOperators(condition.attr.value, valueType)}
          {...condition.op}
        />
      </Operation>
      <Value>
        {valueType === 'date' && (
          <TextField
            type="text"
            placeholder="yyyy-mm-dd"
            label={fmt('global.value')}
            name="val"
            autoComplete="off"
            value={dateMask.value}
            onChange={(val) => {
              dateMask.value = val
              condition.value.onChange(val)
            }}
          />
        )}
        {valueType === 'country' && (
          <Select label={fmt('global.country')} name="val" options={countryOptions} {...condition.value} />
        )}
        {valueType === 'return-type' && (
          <Select label={fmt('global.returnType')} name="val" options={returnTypeOptions} {...condition.value} />
        )}
        {valueType === 'return-reason' && (
          <Select
            label={fmt('global.returnReason')}
            name="val"
            options={['', ...(reasons?.filter(notEmpty).map((r) => r.reason) || [])]}
            {...condition.value}
          />
        )}
        {['text', 'number', 'email'].includes(valueType) && (
          <TextField
            type={valueType as 'text' | 'number' | 'email'}
            label={fmt('global.value')}
            name="val"
            autoComplete="off"
            {...condition.value}
          />
        )}
      </Value>
      <Buttons>
        {removeCondition && (
          <span data-cy="removeRuleConditionButton">
            <Button
              icon={<Icon source={MinusCircleIcon} />}
              size="large"
              variant="tertiary"
              onClick={() => removeCondition()}
            />
          </span>
        )}
        {addCondition && (
          <span data-cy="addRuleConditionButton">
            <Button
              icon={<Icon source={PlusCircleIcon} />}
              size="large"
              variant="tertiary"
              onClick={() => addCondition()}
            ></Button>
          </span>
        )}
      </Buttons>
    </Condition>
  )
}

const Condition = styled.div`
  display: flex;
  gap: var(--p-space-400);
  align-items: flex-end;
  margin-left: var(--p-space-400);
`

const Attribute = styled.div`
  width: 30%;
`
const Operation = styled.div`
  width: 125px;
  @media (max-width: 600px) {
    width: 90px;
  }
`
const Value = styled.div`
  flex-grow: 1;
`

const Buttons = styled.div`
  width: 56px;
  display: flex;
  gap: var(--p-space-300);
  padding-right: var(--p-space-100);

  .Polaris-Icon {
    transform: scale(1.15);
  }
`

export default ConditionRow
