import { Box, Button, FormLayout, InlineError, Select, TextField } from '@shopify/polaris'
import { FormError, notEmpty, useDynamicList, useField, useForm } from '@shopify/react-form'
import { FunctionComponent } from 'react'
import { selectFormatter } from 'store/global/global.selectors'
import { showToast } from 'store/global/global.slice'
import { _, useAppDispatch } from 'store/hooks'
import {
  selectRuleActionOptionsReturnTypes,
  selectRuleActionOptionsShippingTypes,
  selectWhenOptions,
} from 'store/rules/rules.selectors'
import styled from 'styled-components'

import {
  Condition,
  ConditionInput,
  Rule,
  RuleAction,
  RuleActionOption,
  RuleAttribute,
  RuleInput,
  RuleOperator,
  RuleWhen,
  useReturnRuleCreateMutation,
  useReturnRuleDeleteMutation,
  useReturnRuleUpdateMutation,
} from 'gql'

import ConditionRow from './ConditionRow'
import TakeActionRow from './TakeActionRow'

export interface EditRuleFormProps {
  rule?: Rule
  showAdjustWindowAction: boolean
  showApprovalActions: boolean
  onComplete: () => void
}

const EditRuleModal: FunctionComponent<EditRuleFormProps> = ({
  rule,
  showAdjustWindowAction,
  showApprovalActions,
  onComplete,
}) => {
  const dispatch = useAppDispatch()
  const fmt = _(selectFormatter)
  const validActionOptionsReturnTypes = Object.keys(_(selectRuleActionOptionsReturnTypes))
  const validActionOptionsShippingTypes = Object.keys(_(selectRuleActionOptionsShippingTypes))
  const whenOptions = _(selectWhenOptions)
  const [, updateReturnRule] = useReturnRuleUpdateMutation()
  const [, createReturnRule] = useReturnRuleCreateMutation()
  const [, deleteReturnRule] = useReturnRuleDeleteMutation()

  const handleDeleteRule = async () => {
    if (rule?.id) {
      await deleteReturnRule({ id: rule.id })
      dispatch(showToast(fmt('rules.ruleDeleted')))
      return onComplete()
    }
  }

  // Conditions
  const emptyCondition: Condition = {
    id: '',
    uuid: '',
    attr: RuleAttribute.None,
    op: RuleOperator.And,
    value: '',
  }
  const emptyConditionFactory = (): Condition => emptyCondition
  const {
    fields: conditionFields,
    addItem: addCondition,
    removeItem: removeCondition,
  } = useDynamicList(rule ? (rule.conditions as Condition[]) : [emptyCondition], emptyConditionFactory)

  const name = useField({
    value: rule?.name ?? '',
    validates: [notEmpty(fmt('global.nameRequired'))],
  })
  const when = useField(rule?.when)

  const action = useField({
    value: rule?.action,
    validates: (takeActionValue) => {
      if (!takeActionValue) {
        return fmt('editRule.selectAction')
      }
      return ''
    },
  })
  const actionOption = useField(rule?.actionOption)
  const warehouseId = useField(rule?.warehouseId ?? '')
  const windowDays = useField(rule?.windowDays ? rule.windowDays.toString() : '')
  const value = useField(rule?.value ? rule.value.toString() : '')
  const message = useField(rule?.message)

  const { fields, submit, reset, submitErrors, submitting, dirty } = useForm({
    fields: {
      name,
      when,
      action,
      actionOption,
      warehouseId,
      windowDays,
      value,
      message,
    },
    onSubmit: async (submittedData) => {
      const { name, when, action, actionOption, warehouseId, windowDays, value, message } = submittedData

      const errors: FormError[] = []

      // For conditions, make sure each part has a value
      const validConditionFields = conditionFields.filter((f) => f.value.value)
      if (validConditionFields.length !== conditionFields.length) {
        errors.push({
          field: ['conditions'],
          message: fmt('editRule.invalidConditions'),
        })
      }

      // Action option required for certain actions
      let validActionOptions: string[] = []
      if (action) {
        if ([RuleAction.DenyReturn, RuleAction.AdjustWindow].includes(action)) {
          validActionOptions = validActionOptionsReturnTypes
        }
        if ([RuleAction.DenyShipping].includes(action)) {
          validActionOptions = validActionOptionsShippingTypes
        }
      }
      if (validActionOptions.length && (!actionOption || !validActionOptions.includes(actionOption))) {
        errors.push({
          field: ['actionOption'],
          message: fmt('editRule.selectValidOption'),
        })
      }

      // Warehouse required if ship to warehouse
      if (action === RuleAction.ShipToWarehouse && !warehouseId) {
        errors.push({
          field: ['warehouseId'],
          message: fmt('editRule.selectWarehouse'),
        })
      }

      // Message required
      if (action === RuleAction.DenyReturn && !message) {
        errors.push({
          field: ['message'],
          message: fmt('editRule.provideMessage'),
        })
      }

      // Number of days required
      let windowDaysAsNumber: null | number = null
      if (action === RuleAction.AdjustWindow && !windowDays) {
        errors.push({
          field: ['windowDays'],
          message: fmt('editRule.specifyDays'),
        })
      } else if (windowDays) {
        windowDaysAsNumber = parseInt(windowDays, 10)
      }

      // Value required
      if (action === RuleAction.SetFee && !value.trim()) {
        errors.push({
          field: ['value'],
          message: fmt('global.required'),
        })
      }

      // Fail if any errors
      if (errors.length) return { status: 'fail', errors }

      const conditionList = validConditionFields.reduce((accumulator: any, currentValue) => {
        const condition = {
          id: currentValue.id.value || null,
          attr: currentValue.attr.value,
          op: currentValue.op.value,
          value: currentValue.value.value,
        }
        return [...accumulator, condition]
      }, [])

      const variables: RuleInput = {
        id: rule?.id,
        name,
        message: message ?? '',
        when: when ?? RuleWhen.And,
        conditions: conditionList as ConditionInput[],
        action: action ?? RuleAction.DenyReturn,
        actionOption: actionOption ?? RuleActionOption.All,
        warehouseId: warehouseId,
        windowDays: windowDaysAsNumber,
        value,
      }

      if (rule) await updateReturnRule({ returnRule: variables })
      else await createReturnRule({ returnRule: variables })
      dispatch(showToast(fmt('rules.ruleSaved')))
      onComplete()
      return { status: 'success' }
    },
  })

  return (
    <FormLayout>
      <FormLayout.Group>
        <TextField
          label={fmt('editRule.nameThisRule')}
          name="name"
          placeholder={fmt('editRule.nameThisRulePlaceholder')}
          autoComplete="off"
          {...fields.name}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select
          label={fmt('editRule.when')}
          name="when"
          options={Object.values(whenOptions)}
          {...(fields.when as any)}
        />
      </FormLayout.Group>
      {!!submitErrors.length && (
        <FormLayout.Group>
          {submitErrors.map(
            (error) =>
              error.field?.includes('conditions') && (
                <Box key={error.message}>
                  <InlineError message={error.message} fieldID="attr" />
                </Box>
              ),
          )}
        </FormLayout.Group>
      )}
      {conditionFields.map((condition, index) => (
        <ConditionRow
          key={index}
          condition={condition}
          addCondition={index === conditionFields.length - 1 ? () => addCondition() : undefined}
          removeCondition={conditionFields.length > 1 ? () => removeCondition(index) : undefined}
        />
      ))}

      <FormLayout.Group>
        <TakeActionRow
          {...fields}
          showAdjustWindowAction={showAdjustWindowAction}
          showApprovalActions={showApprovalActions}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        {fields.action.value === RuleAction.DenyReturn && (
          <TextField
            name="ruleMessage"
            label={fmt('editRule.ruleMessageLabel')}
            autoComplete="off"
            multiline={4}
            {...fields.message}
          />
        )}
      </FormLayout.Group>
      <FormLayout.Group>
        {rule?.id && (
          <Button onClick={handleDeleteRule} variant="plain" tone="critical">
            Delete
          </Button>
        )}
        <Buttons>
          <Button onClick={onComplete}>Cancel</Button>
          <Button submit onClick={submit} variant="primary">
            {fmt('global.save')}
          </Button>
        </Buttons>
      </FormLayout.Group>
    </FormLayout>
  )
}

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  > button {
    margin-left: var(--p-space-400);
  }
`
export default EditRuleModal
